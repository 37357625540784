/* When a message enters, start off slightly below and transparent. */
.message-enter {
  opacity: 0;
  transform: translateY(20px);
}
/* Animate to fully visible and in place. */
.message-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
/* When a message leaves, start at full opacity. */
.message-exit {
  opacity: 1;
  transform: translateY(0);
}
/* Fade out and slide up as it exits. */
.message-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms, transform 300ms;
}
