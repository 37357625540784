.map-container {
  position: absolute;
  /* border: 1px solid #ccc; */
  /* background: rgb(36, 36, 36); */

  bottom: 0;
  right: 0;
}

/* SVG overlay for connectors */
.connectors {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

/* Container for each environment node */
.environment-node {
  position: absolute;
  text-align: center;
  transform: translate(-50%, -50%);
}

/* Style for the sphere */
.node-sphere {
  width: 30px;
  height: 30px;
  background-color: #eee;
  border: 1px solid #aaa;
  border-radius: 50%;
  margin: 0 auto;
  box-shadow: #333;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
    transition: width 0.1s ease, height 0.1s ease; // Smooth transition for size changes
  &.current{
    width: 50px;
    height: 50px;
  }

  &:hover{
    width:40px;
    height:40px;

  }
   // Pseudo-element for the light gray overlay
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(89, 89, 89, 0.5); // light gray overlay
    border-radius: inherit; // Ensures the overlay stays circular
    pointer-events: none; // Allows clicks to pass through to the sphere
    transition: opacity 0.1s ease; // Smooth transition for the overlay opacity
    opacity: 1; // Default state shows the overlay
  }

  // Remove the overlay when the sphere is current or hovered
  &:hover::before,
  &.current::before {
    opacity: 0;
  }
}

/* Label displayed underneath the sphere */
.node-label {
  margin-top: 4px;
  font-size: 12px;
  color: #333;
}
