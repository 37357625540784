.loading-container {
  font-size: 1.4rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Adjust if needed */
  color: white;
  margin-top:80px;
  margin-right:20%;
}

.dot {
  opacity: 0;
  animation: blink 1.4s infinite;
  animation-fill-mode: both;
}

/* Add delays to each dot for a staggered animation effect */
.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
